import { AuthenticationFacade } from '@cca-common/authentication';
import { NavigationLinkType } from '../navigation';
import { assertInInjectionContext, inject, isDevMode } from '@angular/core';
import {
  PermissionKey,
  PermissionType,
  SystemFeature,
} from '@cca-infra/user-management/v1';

function navigationPermissionCheckHelper(
  authentication: AuthenticationFacade,
  routePermissionKey: PermissionKey | PermissionKey[],
  routePermissionType: PermissionType | undefined,
  realPermission: boolean,
) {
  if (realPermission) {
    return authentication.checkRealPermission(
      routePermissionKey,
      routePermissionType,
    );
  } else {
    return authentication.checkPermission(
      routePermissionKey,
      routePermissionType,
    );
  }
}

export const navigationPermissionCheck = (navigation: NavigationLinkType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isDevMode() && assertInInjectionContext(navigationPermissionCheck);
  const authentication = inject(AuthenticationFacade);

  if (navigation.accessFn) {
    return navigation.accessFn();
  }

  const navigationFeature = navigation.feature as SystemFeature[] | undefined;
  const navigationPermission = navigation.permission as
    | PermissionKey
    | PermissionKey[];
  const navigationRealPermission = navigation.realPermission;

  const hasPermissionAccess = navigationPermissionCheckHelper(
    authentication,
    navigationPermission,
    undefined,
    !!navigationRealPermission,
  );
  if (navigationFeature) {
    return (
      authentication.checkFeature(navigationFeature) && hasPermissionAccess
    );
  }

  return hasPermissionAccess;
};
